<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.yearly-product-received-and-delivery-report') }}</h4>
      </template>
      <template v-slot:body>
        <div class="row">
          <slot v-if="authUser.warehouse_id == null">
              <b-col xs="12" sm="12" md="6" lg="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      :label="$t('org_pro_division.division')"
                      label-for="division_id"
                      >
                      <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_config.region')"
                  label-for="region_id"
                  >
                  <b-form-select
                  plain
                  v-model="search.region_id"
                  :options="regionList"
                  id="division_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_report.district')"
                  label-for="district_id"
                  >
                  <b-form-select
                  plain
                  v-model="search.district_id"
                  :options="regionDistrictLists"
                  id="district_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_report.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.upazila_id"
                    :options="upazilaList"
                    id="upazilla_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('globalTrans.union')"
                    label-for="union_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.union_id"
                    :options="unionList"
                    id="union_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-cols-md="4"
                            label-for="commodity_group_id"
                            :label="$t('warehouse_config.warehouse_type')"
                            >
                            <b-form-select
                            plain
                            v-model="search.warehouse_type_id"
                            :options="warehouseTypeList"
                            id="commodity_group_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                        </b-form-group>
                </b-col>
          </slot>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
            >
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-if="!authUser.warehouse_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              >
              </b-form-select>
          </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              label-for="commodity_group_id"
              :label="$t('warehouse_report.fiscal_year')"
              >
              <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="commodity_group_id"
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
          </b-col>
        </div>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.yearly-product-received-and-delivery-report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Yearly Product Recieve and Delivery Report Sheet"
                  name="yearly-product-receive-delivery-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
                <!-- <export-excel
                    class="btn btn-danger"
                    :data="yearlyReport"
                    worksheet="Yearly Product Recieve and Delivery Report Sheet"
                    type="csv"
                    name="filename.xls">
                    {{ $t('globalTrans.export_csv') }}
                </export-excel> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                          {{ $t('warehouse_report.yearly-product-received-and-delivery-report') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.fiscal_year') }}: </strong> {{ getFiscalYearName(search.fiscal_year_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <!-- <caption class="text-center" style="font-weight:bold; text-color:white">{{ $t('warehouse_report.yearly-product-received-and-delivery-report') }}</caption> -->
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th>{{ $t('warehouse_report.total_farmer') }}</b-th>
                            <b-th>{{ $t('warehouse_report.total_recieve') }}</b-th>
                            <b-th>{{ $t('warehouse_report.total_delivery') }}</b-th>
                          </b-tr>
                        </b-thead>
                         <b-tbody v-for="(infoDetail, index) in report" :key="index">
                                <b-tr :key="index" >
                                  <b-td>{{ $n(index+1) }}</b-td>
                                  <b-td class="capitalize">{{ $i18n.locale === 'bn' ? infoDetail.crop_type_name_bn : infoDetail.crop_type_name }}</b-td>
                                  <b-td class="capitalize">{{ $i18n.locale === 'bn' ? infoDetail.crop_name_bn : infoDetail.crop_name }}</b-td>
                                  <b-td>{{ $n(infoDetail.total_no_of_far) }}</b-td>
                                  <b-td>{{ $n(infoDetail.total_receive) }}</b-td>
                                  <b-td>{{ $n(infoDetail.total_release_quantity) }}</b-td>
                                </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-td colspan="3" variant="secondary" class="text-left">
                              {{ $i18n.locale === 'en' ? 'Gross Total :' : 'সর্ব মোট :' }}<b></b>
                            </b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalFarmer) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(receiveQuantity) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(releaseQuantity) }}</b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { yearlyReportList } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  'Crop Type': 'cropType',
  'Crop Name': 'cropName',
  'Total Farmer': 'totalFarmer',
  'Total Recieve(Quintal)': 'totalReceive',
  'Total Release(Quintal)': 'totalRelease'
}

const excelColumnBn = {
  'শস্যের ধরণ': 'cropType',
  'শস্য নাম': 'cropName',
  'মোট কৃষক': 'totalFarmer',
  'মোট প্রাপ্তি((কুইন্টাল)': 'totalReceive',
  'মোট মুক্তি((কুইন্টাল)': 'totalRelease'
}

export default {
  components: {
      ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      division_id: 0,
      region_id: 0,
      totalFarmer: '',
      receiveQuantity: '',
      releaseQuantity: '',
      search: {
        org_id: 13,
        region_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        fiscal_year_id: 0
      },
      yearlyReportList: [],
      report: [],
      yearlyReport: [],
      farmerReport: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      tempList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      districtLoading: false
    }
  },
  computed: {
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + ' ' + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id) + ' ' + this.$t('warehouse_config.fiscal_year') + ' : ' + this.getFiscalYearName(this.search.fiscal_year_id)
      return [this.$t('warehouse_report.yearly-product-received-and-delivery-report'), '', firstRow, secondRow, '']
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalYearList () {
        return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
    warehouseTypeList () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
     dataCustomizeExcel () {
        const footerData = this.getFooter()
        const list = this.report
        var newDataxl = list.concat(footerData)
        const customizeData = newDataxl.map(item => {
            if (this.$i18n.locale === 'bn') {
                item.cropType = item.crop_type_name_bn
                item.cropName = item.crop_name_bn
                item.totalFarmer = this.$n(item.total_no_of_far)
                item.totalReceive = this.$n(item.total_receive)
                item.totalRelease = this.$n(item.total_release_quantity)
            } else {
                item.cropType = item.crop_type_name
                item.cropName = item.crop_name
                item.totalFarmer = this.$n(item.total_no_of_far)
                item.totalReceive = this.$n(item.total_receive)
                item.totalRelease = this.$n(item.total_release_quantity)
            }
            return Object.assign({}, item)
        })
        return customizeData
    }
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.region_id': function (newVal, oldVal) {
        this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    }
  },
  methods: {
    paginationDataLoad () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: ''
      }
    },
    searchData () {
            this.loadData()
            this.showData = true
            this.showHeading = true
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        RestApi.getData(warehouseServiceBaseUrl, yearlyReportList, this.search).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.tableShow = true
                    this.$store.dispatch('setList', this.dataList(response))
                }
            } else {
              this.yearlyReportList = []
              this.report = []
              this.farmerReport = []
              this.totalFarmer = ''
              this.receiveQuantity = ''
              this.releaseQuantity = ''
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
            if (error) {
              //
            }
            this.$store.commit('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getFooter () {
        const footer = [{
        crop_id: null,
        crop_name: null,
        crop_name_bn: null,
        crop_type_id: this.$t('sitePreference.grossTotal'),
        crop_type_name: this.$t('sitePreference.grossTotal'),
        crop_type_name_bn: this.$t('sitePreference.grossTotal'),
        total_no_of_far: this.totalFarmer,
        total_receive: this.receiveQuantity,
        total_release_quantity: this.releaseQuantity,
        total_remaining_qnty: this.receiveQuantity - this.releaseQuantity
        }]
        return footer
    },
    dataList (data) {
        var tF = 0
        var relQ = 0
        var recQ = 0
        if (data.data) {
            this.yearlyReportList = data.data
        } else {
            this.yearlyReportList = []
        }
        var myArray = this.yearlyReportList
        var groups = {}
        for (var i = 0; i < myArray.length; i++) {
                var cropType = myArray[i].crop_type_id
                if (!groups[cropType]) {
                    groups[cropType] = []
                }
                var newData = {
                    crop_id: myArray[i].crop_id,
                    total_no_of_far: myArray[i].total_no_of_far,
                    total_receive: myArray[i].total_receive,
                    total_release_quantity: myArray[i].total_release_quantity,
                    total_remaining_qnty: myArray[i].total_remaining_qnty
                }
                groups[cropType].push(newData)
            }
        myArray = []
        for (var Name in groups) {
            myArray.push({ crop_type_id: Name, data: groups[Name] })
        }
        const newarr = myArray
        this.yearlyReportList = newarr
       this.yearlyReportList.forEach(item => {
            item.data.forEach((listItem, index) => {
                if (index === 0) {
                    listItem.crop_type_id = item.crop_type_id
                } else {
                     listItem.crop_type_id = null
                }
            })
        })
        const pdf = []
        this.farmerReport = this.yearlyReportList
        this.farmerReport.forEach((item) => {
        item.data.forEach((list) => {
            pdf.push(list)
           tF = tF + list.total_no_of_far
           recQ = recQ + list.total_receive
           relQ = relQ + list.total_release_quantity
        })
        })
        this.receiveQuantity = recQ
        this.releaseQuantity = relQ
        this.totalFarmer = tF
        const CustomData = pdf
        const warehouseTypeList = this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
        const commodityNameList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
        const listData = CustomData.map(item => {
                const warehouseTypeObject = warehouseTypeList.find(warehouseType => warehouseType.value === parseInt(item.crop_type_id))
                const commodityNameObject = commodityNameList.find(commodityName => commodityName.value === item.crop_id)
                var warehouseTypeData = {
                                crop_type_name: null,
                                crop_type_name_bn: null
                                }
                if (warehouseTypeObject) {
                        warehouseTypeData = {
                                crop_type_name: warehouseTypeObject !== undefined ? warehouseTypeObject.text_en : '',
                                crop_type_name_bn: warehouseTypeObject !== undefined ? warehouseTypeObject.text_bn : ''
                                }
                        } else {
                        warehouseTypeData = {
                                crop_type_name: null,
                                crop_type_name_bn: null
                                }
                        }
                var commodityNameData = {
                                crop_name: null,
                                crop_name_bn: null
                        }
                if (commodityNameObject) {
                        commodityNameData = {
                                crop_name: commodityNameObject !== undefined ? commodityNameObject.text_en : '',
                                crop_name_bn: commodityNameObject !== undefined ? commodityNameObject.text_bn : ''
                        }
                }
                return Object.assign({}, item, warehouseTypeData, commodityNameData)
            })
        this.report = listData
        return listData
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_farmer'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_recieve'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_delivery'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Yearly Product Received and Delivery Report' : 'বার্ষিক পণ্য প্রাপ্ত এবং বিতরণ রিপোর্ট'
      // const columnWids = ['10%', '10%', '10%', '10%', '15%', '12%', '12%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.fiscal_year')} : ${this.getFiscalYearName(this.search.fiscal_year_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, '*', extraData, 'yearly-product-receive-delivery-report')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'total_no_of_far' },
          { key: 'total_receive' },
          { key: 'total_release_quantity' }
        ]
      var serial = 0
      var newArray = []
      newArray = this.report
      var listData = newArray.map(item => {
            serial += 1
            const rowData = keys.map((keyItem, index) => {
                if (keyItem.key === 'serial_no') {
                    return { text: this.$n(serial) }
                }
                if (keyItem.key === 'crop_type_name') {
                    return { text: item.crop_type_name }
                }
                if (keyItem.key === 'crop_type_name_bn') {
                    return { text: item.crop_type_name_bn }
                }
                if (keyItem.key === 'crop_name') {
                    return { text: item.crop_name }
                }
                if (keyItem.key === 'crop_name_bn') {
                    return { text: item.crop_name_bn }
                }
                if (keyItem.key === 'total_no_of_far') {
                    return { text: this.$n(item.total_no_of_far) }
                }
                if (keyItem.key === 'total_receive') {
                    return { text: this.$n(item.total_receive) }
                }
                if (keyItem.key === 'total_release_quantity') {
                    return { text: this.$n(item.total_release_quantity) }
                }

            return { text: item[keyItem.key] }
            })
        return rowData
      })
      const gross = [
            this.$i18n.locale === 'en' ? { text: 'GrossTotal :' } : { text: 'সর্ব মোট :' },
            { text: '' },
            { text: '' },
            { text: this.$n(this.totalFarmer) },
            { text: this.$n(this.receiveQuantity) },
            { text: this.$n(this.releaseQuantity) }
        ]
      listData.push(gross)
      return listData
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(document => document.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                    }
                })
            }
        })
        this.districtLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getFiscalYearName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
